@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-drawer {
  width: @eva-header;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  .eva-drawer__inner {
    display: flex;
    flex-direction: column;
    min-height: 0;
    z-index: 1000;
    height: 100%;
    transition-property: width;
    transition-duration: 0.2s;
    cursor: pointer;
    &:hover {
      overflow-y: auto;
    }
  }
}
