.eva-repeater-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: 56px;
  gap: 8px;
}
.eva-repeater-footer .eva-repeater-footer__select {
  max-width: 150px;
  margin: 0!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-input__append-inner,
.eva-repeater-footer .eva-repeater-footer__select .v-input__prepend-inner {
  margin-top: 6px!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-input__slot {
  margin-bottom: 0!important;
  min-height: 36px!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-text-field__details {
  display: none!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-select__selections {
  padding: 0!important;
}
.eva-repeater-footer .v-pagination li:last-child .v-pagination__navigation:last-child {
  margin-right: 0;
}
