.eva-json-editor {
  height: 100%;
  width: 100%;
}
.eva-json-editor .v-select__selections {
  padding: 12px 0!important;
}
.eva-json-editor .v-select__selections input {
  display: none;
}
.eva-json-editor .v-input {
  background-color: #F5F5F5 !important;
}
.eva-json-editor .v-input,
.eva-json-editor .v-input__control,
.eva-json-editor .v-input__slot,
.eva-json-editor .v-select__selections {
  height: 100%!important;
}
.eva-json-editor .v-text-field__details {
  display: none;
}
.eva-json-editor .v-input__slot {
  /*padding: 0 2px!important;*/
}
.eva-json-editor .v-input__append-inner {
  display: none;
}
.eva-json-editor .jsoneditor-menu {
  display: none;
}
.eva-json-editor .jsoneditor {
  border: none;
}
.eva-json-editor .ace_scroller {
  background-color: #F5F5F5 !important;
}
.eva-json-editor .jsoneditor-vue {
  height: 100%;
}
.eva-json-editor .jsoneditor-tree {
  min-height: 100%;
}
.eva-json-editor .jsoneditor-outer {
  min-height: 100%;
  margin: 0;
  padding: 0;
}
