.eva-repeater-header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 56px;
  gap: 8px;
  align-items: center;
}
.eva-repeater-header .eva-repeater-header__caption {
  font-weight: bold;
  font-size: 1.2rem;
}
.eva-repeater-header .eva-repeater-header__context {
  max-width: 250px;
  margin: 0!important;
}
.eva-repeater-header .eva-repeater-header__context .v-input__append-inner,
.eva-repeater-header .eva-repeater-header__context .v-input__prepend-inner {
  margin-top: 6px!important;
}
.eva-repeater-header .eva-repeater-header__context .v-input__slot {
  margin-bottom: 0!important;
  min-height: 36px!important;
}
.eva-repeater-header .eva-repeater-header__context .v-text-field__details {
  display: none!important;
}
