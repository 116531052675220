/* TODO
  1. Если выставлен bold, то он подгружается позже
*/

html, body {
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: Roboto,sans-serif;
  font-size: 14px;
}
