.eva-info-text {
  color: @eva-info-color;
}
.eva-success-text {
  color: @eva-success-color;
}
.eva-warning-text {
  color: @eva-warning-color;
}
.eva-info-text {
  color: @eva-error-color;
}

.eva-info-layout {
  background-color: @eva-info-color;
  color: white;
}
.eva-success-layout {
  background-color: @eva-success-color;
  color: white;
}
.eva-warning-layout {
  background-color: @eva-warning-color;
  color: white;
}
.eva-error-layout {
  background-color: @eva-error-color;
  color: white;
}

