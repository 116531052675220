@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-text {
  align-self: center;
  font-size: 1rem;
  &.eva-text--bolder {
    font-weight: bolder;
  }
  &.eva-text--bold {
    font-weight: bold;
  }
}
