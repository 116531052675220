@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-layout {
  display: flex;
  width: 100%;
  overflow: hidden;
  &.eva-layout--row {
    flex-direction: row;
  }
  &.eva-layout--column {
    flex-direction: column;
  }
  &.eva-layout--fill {
    flex: 1;
  }
  &.eva-layout--gap {
    gap: @eva-padding;
  }
  &.eva-layout--no-shrink {
   flex-shrink: 0;
  }
  &.eva-layout--no-grow {
    flex-grow: 0;
  }
  &.eva-layout--scroll {
    flex: 1;
    overflow: auto;
  }
  &.eva-layout--padding {
    padding: @eva-padding;
  }
  &.eva-layout--scroll-hide {
    flex: 1;
    overflow: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
}
