@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-table1 {
  height: 100%;
  display: flex;
  gap: @eva-padding;
  flex-direction: column;

  .eva-table__toolbar {
    display: flex;
    flex-grow: 0;
    gap: @eva-padding;
    align-items: center;
    .eva-table__header {
      font-weight: bold;
      font-size: 1.2rem;
    }
    .eva-table__total {
      display: flex;
      justify-content: center;
      min-width: 64px;
      background-color: @eva-info-color;
      border-color: @eva-info-color;
      min-height: 36px;
      color: white;
      border-radius: (@eva-padding * 2);
      font-weight: bold;
      font-size: 1.2rem;
    }
    .eva-table__context {
      max-width : 250px;
      margin: 0!important;
      .v-input__prepend-inner {
        margin-top: 6px!important;
      }
      .v-input__slot {
        margin-bottom: 0!important;;
        min-height: 36px!important;
      }
      .v-text-field__details {
        display: none!important;;
      }
    }
  }

  .eva-table__alert {

  }

  .eva-table__content {

  }

  .eva-table__pagination {
    height: @eva-header;
  }

  .v-data-table {
    border-radius: 0!important;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    width: 100%;
    th {
      white-space: nowrap!important;
      text-align: start;
    }
    td {
      border-radius: 0!important;
      padding: 0;
    }
  }
}
