@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-repeater-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: @eva-header;
  gap: @eva-padding;
  .eva-repeater-footer__select {
    max-width : 150px;
    margin: 0!important;
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 6px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;;
      min-height: 36px!important;
    }
    .v-text-field__details {
      display: none!important;;
    }
    .v-select__selections {
      padding: 0!important;
    }
  }
  .v-pagination {
    li:last-child {
      .v-pagination__navigation:last-child {
        margin-right: 0;
      }
    }
  }
}
