.eva-repeater {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.eva-repeater .eva-repeater__content {
  overflow: auto;
  flex-grow: 1;
  margin-bottom: 8px;
}
.eva-repeater .eva-repeater__content .v-progress-circular {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  z-index: 100;
}
