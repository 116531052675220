@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-repeater-header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: @eva-header;
  gap: @eva-padding;
  align-items: center;
  .eva-repeater-header__caption {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .eva-repeater-header__context {
    max-width : 250px;
    margin: 0!important;
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 6px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;;
      min-height: 36px!important;
    }
    .v-text-field__details {
      display: none!important;;
    }
  }
}
