.eva-drawer {
  width: 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.eva-drawer .eva-drawer__inner {
  display: flex;
  flex-direction: column;
  min-height: 0;
  z-index: 1000;
  height: 100%;
  transition-property: width;
  transition-duration: 0.2s;
  cursor: pointer;
}
.eva-drawer .eva-drawer__inner:hover {
  overflow-y: auto;
}
