.eva-table1 {
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.eva-table1 .eva-table__toolbar {
  display: flex;
  flex-grow: 0;
  gap: 8px;
  align-items: center;
}
.eva-table1 .eva-table__toolbar .eva-table__header {
  font-weight: bold;
  font-size: 1.2rem;
}
.eva-table1 .eva-table__toolbar .eva-table__total {
  display: flex;
  justify-content: center;
  min-width: 64px;
  background-color: #2196F3;
  border-color: #2196F3;
  min-height: 36px;
  color: white;
  border-radius: 16px;
  font-weight: bold;
  font-size: 1.2rem;
}
.eva-table1 .eva-table__toolbar .eva-table__context {
  max-width: 250px;
  margin: 0!important;
}
.eva-table1 .eva-table__toolbar .eva-table__context .v-input__prepend-inner {
  margin-top: 6px!important;
}
.eva-table1 .eva-table__toolbar .eva-table__context .v-input__slot {
  margin-bottom: 0!important;
  min-height: 36px!important;
}
.eva-table1 .eva-table__toolbar .eva-table__context .v-text-field__details {
  display: none!important;
}
.eva-table1 .eva-table__pagination {
  height: 56px;
}
.eva-table1 .v-data-table {
  border-radius: 0!important;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  width: 100%;
}
.eva-table1 .v-data-table th {
  white-space: nowrap!important;
  text-align: start;
}
.eva-table1 .v-data-table td {
  border-radius: 0!important;
  padding: 0;
}
