@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
