@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-repeater {
  height: 100%;
  display: flex;
  flex-direction: column;
  .eva-repeater__content {
    overflow: auto;
    flex-grow: 1;
    margin-bottom: @eva-padding;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
}
