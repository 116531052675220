@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-list {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  .v-list-item {
    min-height: 40px;
    .v-list-item__icon {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 32px;
    }
  }
  .v-list-group__items {
    .v-list-item {
      padding-left: 56px;
    }
  }
}
