/* TODO
  1. Если выставлен bold, то он подгружается позже
*/
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}
.eva-info-text {
  color: #2196F3;
}
.eva-success-text {
  color: #4CAF50;
}
.eva-warning-text {
  color: #FFC107;
}
.eva-info-text {
  color: #F44336;
}
.eva-info-layout {
  background-color: #2196F3;
  color: white;
}
.eva-success-layout {
  background-color: #4CAF50;
  color: white;
}
.eva-warning-layout {
  background-color: #FFC107;
  color: white;
}
.eva-error-layout {
  background-color: #F44336;
  color: white;
}
/* Works on Firefox */
.eva-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}
/* Works on Chrome, Edge, and Safari */
.eva-scrollbar::-webkit-scrollbar {
  width: 12px;
}
.eva-scrollbar::-webkit-scrollbar-track {
  background: orange;
}
.eva-scrollbar::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}
*::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: 0.1px solid #B7B7B7;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
.eva-border1 {
  /*box-shadow:
    1px 0 0 0 #888,
    0 1px 0 0 #888,
    1px 1px 0 0 #888,
    1px 0 0 0 #888 inset,
    0 1px 0 0 #888 inset;*/
  /*border: solid 1px @eva-border-color;*/
}
.eva-border {
  border: 2px solid #e0e0e0;
}
.eva-border-x {
  border-left: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
}
.eva-border-y {
  border-top: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
}
.eva-border-left {
  border-left: 2px solid #e0e0e0;
}
.eva-border-right {
  border-right: 2px solid #e0e0e0;
}
.eva-border-top {
  border-top: 2px solid #e0e0e0;
}
.eva-border-bottom {
  border-bottom: 2px solid #e0e0e0;
}
.eva-page.eva-theme-light {
  background-color: white;
  color: black;
}
.eva-page.eva-theme-light .eva-background {
  background-color: white;
  color: black;
}
.eva-page.eva-theme-dark {
  background-color: black;
  color: white;
}
.eva-page.eva-theme-dark .eva-background {
  background-color: black;
  color: white;
}
