.eva-table {
  padding: 8px;
}
.eva-table table {
  width: 100%;
  border: none;
  border-spacing: 0;
  border-collapse: separate;
}
.eva-table table tr {
  border: none;
}
.eva-table table th {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 42px;
  border: none;
  background-color: white;
  white-space: nowrap;
  z-index: 1;
}
.eva-table table td {
  text-align: left;
  font-size: 1rem;
  padding: 4px 0;
  height: 32px;
  border: none;
}
.eva-table table th,
.eva-table table td:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.eva-table table .eva-table__progress {
  padding: 0;
  border: none;
  height: auto;
}
.eva-table table .eva-table__progress .v-progress-linear {
  height: 2px !important;
}
