/* Works on Firefox */
.eva-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
.eva-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.eva-scrollbar::-webkit-scrollbar-track {
  background: orange;
}

.eva-scrollbar::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}



*::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}
*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: .1px solid #B7B7B7;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
