@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-header {
  display: flex;
  flex-direction: row;
  gap: @eva-padding;
  width: 100%;
  height: @eva-header;
  align-items: center;
  padding: @eva-padding;
}
