@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-pre {
  align-self: center;
  &.eva-pre--bolder {
    font-weight: bolder;
  }
  &.eva-pre--bold {
    font-weight: bold;
  }
}
