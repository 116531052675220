@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-json-editor {
  height: 100%;
  width: 100%;

  .v-select__selections {
    padding: 12px 0!important;
    input {
      display: none;
    }
  }
  .v-input {
    background-color: #F5F5F5!important;
  }
  .v-input,
  .v-input__control,
  .v-input__slot,
  .v-select__selections {
    height: 100%!important;
  }
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    /*padding: 0 2px!important;*/
  }
  .v-input__append-inner {
    display: none;
  }
  .jsoneditor-menu {
    display: none;
  }
  .jsoneditor {
    border: none;
  }
  .ace_scroller {
    background-color: #F5F5F5!important;
  }
  .jsoneditor-vue {
    height: 100%;
  }
  .jsoneditor-tree{
    min-height: 100%;
  }
  .jsoneditor-outer{
    min-height:100%;
    margin: 0;
    padding: 0;
  }
}
