.eva-border1 {
  /*box-shadow:
    1px 0 0 0 #888,
    0 1px 0 0 #888,
    1px 1px 0 0 #888,
    1px 0 0 0 #888 inset,
    0 1px 0 0 #888 inset;*/
  /*border: solid 1px @eva-border-color;*/
}

.eva-border {
  border: @eva-border-width solid @eva-border-color;
}

.eva-border-x {
  border-left: @eva-border-width solid @eva-border-color;
  border-right: @eva-border-width solid @eva-border-color;
}

.eva-border-y {
  border-top: @eva-border-width solid @eva-border-color;
  border-bottom: @eva-border-width solid @eva-border-color;
}

.eva-border-left {
  border-left: @eva-border-width solid @eva-border-color;
}

.eva-border-right {
  border-right: @eva-border-width solid @eva-border-color;
}

.eva-border-top {
  border-top: @eva-border-width solid @eva-border-color;
}

.eva-border-bottom {
  border-bottom: @eva-border-width solid @eva-border-color;
}
