@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-row-layout {
  &.eva-row-layout--hide-scroll {
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
  display: flex;
  flex-direction: row;

}
