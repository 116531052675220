.set-background(@theme, @settings) {
  .eva-page.eva-theme-@{theme} {
    background-color: @settings[background];
    color: @settings[foreground];
  }
  .eva-page.eva-theme-@{theme} {
    .eva-background {
      background-color: @settings[background];
      color: @settings[foreground];
    }
  }
}
