.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
