.eva-overlay {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  opacity: 0.46;
  background-color: #212121;
}
