.eva-list {
  width: 100%;
  padding: 0;
  font-size: 1rem;
}
.eva-list .v-list-item {
  min-height: 40px;
}
.eva-list .v-list-item .v-list-item__icon {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 32px;
}
.eva-list .v-list-group__items .v-list-item {
  padding-left: 56px;
}
