@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-grid {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .eva-grid__item {

  }
}
