.eva-text {
  align-self: center;
  font-size: 1rem;
}
.eva-text.eva-text--bolder {
  font-weight: bolder;
}
.eva-text.eva-text--bold {
  font-weight: bold;
}
