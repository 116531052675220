@import '/home/gitlab-runner/builds/7ZLpFSbW/0/lagom/web_core/src/eva/client/styles/variables.less'; 
.eva-table {
  padding: @eva-padding;
  table {
    width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    tr {
      border: none;
    }
    th {
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
      padding: @eva-padding 0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 42px;
      border: none;
      background-color: white;
      white-space: nowrap;
      z-index: 1;
    }
    td {
      text-align: left;
      font-size: 1rem;
      padding: (@eva-padding / 2) 0;
      height: 32px;
      border: none;
    }
    th,
    td:not(:last-child) {
      border-bottom: (@eva-border-width / 2) solid rgba(0,0,0,.12);
    }
    .eva-table__progress {
      padding: 0;
      border: none;
      height: auto;
      .v-progress-linear {
        height: @eva-border-width!important;
      }
    }
  }
}
